import { useState } from 'react';
import { supabase } from '@/lib/supabase';

export function useLeaderboard() {
  const [isUpdating, setIsUpdating] = useState(false);

  async function getCurrentUserId(): Promise<string | null> {
    const accessCode = localStorage.getItem('gameAccessCode');
    if (!accessCode) {
      console.error('No access code found');
      return null;
    }

    const { data, error } = await supabase
      .from('users')
      .select('id')
      .eq('access_code', accessCode)
      .single();

    if (error) {
      console.error('Error fetching user:', error);
      return null;
    }

    return data?.id;
  }

  async function updateWins(teamName: string) {
    if (!teamName) {
      console.error('No team name provided');
      return;
    }

    setIsUpdating(true);
    try {
      const userId = await getCurrentUserId();
      if (!userId) {
        throw new Error('User not found');
      }

      // First, try to get the existing team record
      const { data: existingTeam, error: lookupError } = await supabase
        .from('leaderboard')
        .select('*')
        .eq('user_id', userId)
        .eq('team_name', teamName)
        .single();

      if (lookupError && lookupError.code !== 'PGRST116') {
        throw lookupError;
      }

      if (existingTeam) {
        // Update existing team's wins with direct update
        const { error: updateError } = await supabase
          .from('leaderboard')
          .update({ 
            total_wins: existingTeam.total_wins + 1
          })
          .eq('user_id', userId)
          .eq('team_name', teamName);

        if (updateError) {
          console.error('Error updating wins:', updateError);
          throw updateError;
        }
      } else {
        // Create new team entry
        const { error: insertError } = await supabase
          .from('leaderboard')
          .insert([{ 
            user_id: userId,
            team_name: teamName, 
            total_wins: 1
          }]);

        if (insertError) throw insertError;
      }

      console.log('Successfully updated leaderboard for team:', teamName);
    } catch (error) {
      console.error('Error updating leaderboard:', error);
      throw error;
    } finally {
      setIsUpdating(false);
    }
  }

  async function fetchLeaderboard() {
    try {
      const userId = await getCurrentUserId();
      if (!userId) {
        return [];
      }

      const { data, error } = await supabase
        .from('leaderboard')
        .select('*')
        .eq('user_id', userId)
        .order('total_wins', { ascending: false });

      if (error) {
        console.error('Error fetching leaderboard:', error);
        return [];
      }

      return data || [];
    } catch (error) {
      console.error('Error fetching leaderboard:', error);
      return [];
    }
  }

  return { updateWins, fetchLeaderboard, isUpdating };
}